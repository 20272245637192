<template>
	<v-container fluid>
		<v-overlay opacity=".8" absolute v-if="loadingData">
			<v-progress-circular
				indeterminate
				size="100"
				width="10"
				color="primary"
			></v-progress-circular>
		</v-overlay>
		<v-form ref="formularioContrato">
			<v-row>
				<v-col cols="auto">
					<v-checkbox
						hide-details
						:value="contrato.enviarSMS"
						class="ml-1"
						label="Enviar firma por SMS"
						disabled
					></v-checkbox>
				</v-col>
				<v-col cols="auto">
					<v-checkbox
						hide-details
						:value="contrato.enviarEmail"
						class="ml-1"
						label="Enviar firma por Email"
						disabled
					></v-checkbox>
				</v-col>
			</v-row>

			<v-subheader v-if="!(contrato.enviarSMS || contrato.enviarEmail)"
				>Si no se selecciona un tipo de firma la firma será manual</v-subheader
			>

			<v-row v-if="incidencias.length > 0" class="d-flex">
				<v-col
					cols="12"
					class="col-md-6"
					v-for="(incidencia, index) in incidencias"
					:key="index"
				>
					<v-alert dense type="error">
						{{ incidencia.Mensaje }}
					</v-alert>
				</v-col>
			</v-row>

			<v-tabs v-model="tab" show-arrows>
				<v-tab tab="1">General</v-tab>
				<v-tab tab="2">Contrato</v-tab>
				<v-tab tab="3">Otros</v-tab>
				<v-tab tab="4"> Documentacion </v-tab>
			</v-tabs>

			<v-tabs-items touchless :value="tab">
				<v-tab-item eager>
					<v-row>
						<v-col cols="12" class="col-md-6">
							<v-card outlined>
								<v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4">
									<v-card-subtitle class="pa-0">
										<v-icon small>mdi-pencil</v-icon>&nbsp;Punto Suministro
									</v-card-subtitle>
									<v-spacer />
								</v-sheet>

								<v-divider />

								<aux-input title="CUPS *">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="INTRODUZCA CUPS"
										:value="contrato.puntoSuministro.cups"
										:rules="[rules.cups, rules.req]"
									>
									</v-text-field>
								</aux-input>
								<v-divider />
								<aux-input title="Código Distribuidora *">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="INTRODUZCA CODIGO DISTRIBUIDORA"
										:value="contrato.puntoSuministro.codDistribuidora"
										:rules="[rules.req]"
									/>
								</aux-input>
								<v-divider />

								<aux-input title="Codigo Comercializadora">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder=""
										:value="contrato.puntoSuministro.codComercializadora"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Ciudad *">
									<v-autocomplete
										disabled
										dense
										hide-details="auto"
										hide-no-data
										filled
										placeholder="BUSCAR CIUDAD POR NOMBRE"
										:value="contrato.puntoSuministro.ciudad"
										item-text="texto"
										clearable
										:return-object="true"
										:loading="isLoading"
										:items="ciudadesPosibles.puntoSuministro"
										:search-input.sync="busqueda.ciudad.puntoSuministro"
										:hint="contrato.puntoSuministro.sugerenciaCiudad"
										persistent-hint
										cache-items
										:rules="[rules.req]"
									>
									</v-autocomplete>
								</aux-input>
								<v-divider />
								<aux-input title="Provincia *">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="INTRODUZCA PROVINCIA"
										:rules="[rules.req]"
										:value="contrato.puntoSuministro.provincia"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Calle *">
									<v-combobox
										disabled
										:value="contrato.puntoSuministro.calle"
										dense
										hide-no-data
										hide-details="auto"
										filled
										placeholder="Nombre Calle"
										item-text="texto"
										clearable
										:return-object="true"
										:loading="isLoadingCalle"
										:items="callesPosibles.puntoSuministro"
										:rules="[rules.req]"
										@change="calleCambio()"
										:hint="contrato.puntoSuministro.sugerenciaCalle"
										persistent-hint
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Número *">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="INTRODUZCA NUMERO"
										:rules="[rules.req]"
										:value="contrato.puntoSuministro.numero"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Aclarador">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="INTRODUZCA ACLARADOR"
										:value="contrato.puntoSuministro.aclarador"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Código Postal *">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="INTRODUZCA CODIGO POSTAL"
										:rules="[rules.req]"
										:value="contrato.puntoSuministro.codPostal"
									/>
								</aux-input>
								<v-divider />
							</v-card>
						</v-col>

						<v-col cols="12" class="col-md-6">
							<v-card outlined>
								<v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4">
									<v-card-subtitle class="pa-0">
										<v-icon small>mdi-pencil</v-icon>&nbsp;Detalles de
										contratación
									</v-card-subtitle>
								</v-sheet>

								<v-divider />
								<aux-input title="Tarifa *">
									<v-select
										disabled
										filled
										placeholder="Seleccione una tarifa de Acceso*"
										dense
										hide-details="auto"
										:value="contrato.detallesContratacion.tarifa"
										clearable
										:items="selects.contratacion.tarifas"
										item-text="TextoTarifa"
										:rules="[rules.req]"
										return-object
										@change="getProductoContratacion"
									/>
								</aux-input>

								<v-divider />
								<aux-input title="Producto *">
									<v-select
										disabled
										filled
										:placeholder="
											!esProductoFlexible
												? 'Seleccione un producto*'
												: 'Producto flexible'
										"
										dense
										hide-details="auto"
										:value="contrato.detallesContratacion.producto"
										:items="selects.contratacion.producto"
										item-text="TextoTarifaGrupo"
										clearable
										return-object
									/>
								</aux-input>

								<v-divider />

								<template
									v-if="
										contrato.detallesContratacion.producto &&
										contrato.detallesContratacion.producto.TextoTarifaGrupo.includes(
											'FLEXIBLE'
										)
									"
								>
									<v-expansion-panels flat :value="detallesFlexible.show">
										<v-expansion-panel>
											<v-expansion-panel-header style="background: #f3f3f3">
												<h5>Detalles Producto Flexible</h5>
											</v-expansion-panel-header>
											<v-expansion-panel-content style="background: #f3f3f3">
												<v-row>
													<v-col cols="12" sm="4">
														<h5>Fee término de energía</h5>
													</v-col>
													<v-col>
														<v-select
															disabled
															dense
															hide-details="auto"
															filled
															:value="contrato.productoFlexible.feeEnergia"
															:items="selects.flexible.feeEnergia"
															suffix="EUR/MWh"
														/>
													</v-col>
												</v-row>
												<v-row class="pa-2">
													<v-col cols="12" class="col-sm-6 col-md-4">
														<v-select
															disabled
															dense
															hide-details="auto"
															filled
															:value="contrato.productoFlexible.p1"
															label="P1"
															suffix="€"
															:items="selects.flexible.incrementoPotencia"
															v-show="atrPotencia.p1"
															:rules="[atrPotencia.p1 ? rules.req : true]"
														/>
													</v-col>
													<v-col cols="12" class="col-sm-6 col-md-4">
														<v-select
															disabled
															dense
															hide-details="auto"
															filled
															:value="contrato.productoFlexible.p2"
															label="P2"
															suffix="€"
															:items="selects.flexible.incrementoPotencia"
															v-show="atrPotencia.p2"
															:rules="[atrPotencia.p2 ? rules.req : true]"
														/>
													</v-col>
													<v-col cols="12" class="col-sm-6 col-md-4">
														<v-select
															disabled
															dense
															hide-details="auto"
															filled
															:value="contrato.productoFlexible.p3"
															label="P3"
															suffix="€"
															:items="selects.flexible.incrementoPotencia"
															v-show="atrPotencia.p3"
															:rules="[atrPotencia.p3 ? rules.req : true]"
														/>
													</v-col>
													<v-col cols="12" class="col-sm-6 col-md-4">
														<v-select
															disabled
															dense
															hide-details="auto"
															filled
															:value="contrato.productoFlexible.p4"
															label="P4"
															suffix="€"
															:items="selects.flexible.incrementoPotencia"
															v-show="atrPotencia.p4"
															:rules="[atrPotencia.p4 ? rules.req : true]"
														/>
													</v-col>
													<v-col cols="12" class="col-sm-6 col-md-4">
														<v-select
															disabled
															dense
															hide-details="auto"
															filled
															:value="contrato.productoFlexible.p5"
															label="P5"
															suffix="€"
															:items="selects.flexible.incrementoPotencia"
															v-show="atrPotencia.p5"
															:rules="[atrPotencia.p5 ? rules.req : true]"
														/>
													</v-col>
													<v-col cols="12" class="col-sm-6 col-md-4">
														<v-select
															disabled
															dense
															hide-details="auto"
															filled
															:value="contrato.productoFlexible.p6"
															label="P6"
															suffix="€"
															:items="selects.flexible.incrementoPotencia"
															v-show="atrPotencia.p6"
															:rules="[atrPotencia.p6 ? rules.req : true]"
														/>
													</v-col>
												</v-row>
												<v-row class="pa-2">
													<v-col cols="12" class="col-sm-6 col-md-4">
														<v-text-field
															disabled
															dense
															hide-details="auto"
															filled
															:value="atrPotencia.p1"
															label="P1"
															suffix="€"
															v-show="atrPotencia.p1"
														/>
													</v-col>
													<v-col cols="12" class="col-sm-6 col-md-4">
														<v-text-field
															disabled
															dense
															hide-details="auto"
															filled
															:value="atrPotencia.p2"
															label="P2"
															suffix="€"
															v-show="atrPotencia.p2"
														/>
													</v-col>
													<v-col cols="12" class="col-sm-6 col-md-4">
														<v-text-field
															disabled
															dense
															hide-details="auto"
															filled
															:value="atrPotencia.p3"
															label="P3"
															suffix="€"
															v-show="atrPotencia.p3"
														/>
													</v-col>
													<v-col cols="12" class="col-sm-6 col-md-4">
														<v-text-field
															disabled
															dense
															hide-details="auto"
															filled
															:value="atrPotencia.p4"
															label="P4"
															suffix="€"
															v-show="atrPotencia.p4"
														/>
													</v-col>
													<v-col cols="12" class="col-sm-6 col-md-4">
														<v-text-field
															disabled
															dense
															hide-details="auto"
															filled
															:value="atrPotencia.p5"
															label="P5"
															suffix="€"
															v-show="atrPotencia.p5"
														/>
													</v-col>
													<v-col cols="12" class="col-sm-6 col-md-4">
														<v-text-field
															disabled
															dense
															hide-details="auto"
															filled
															:value="atrPotencia.p6"
															label="P6"
															suffix="€"
															v-show="atrPotencia.p6"
														/>
													</v-col>
												</v-row>
											</v-expansion-panel-content>
										</v-expansion-panel>
									</v-expansion-panels>

									<v-divider />
								</template>

								<!-- <v-row class="ma-1">
									<v-col cols="12" sm="4">
										<h5>Perfil facturacion*</h5>
									</v-col>
									<v-col cols="12" sm="8">
										<v-select disabled
											filled
											placeholder="Seleccione un Perfil de Facturacion*"
											dense
											hide-details="auto"
											:value="contrato.detallesContratacion.perfilFacturacion"
											:items="selects.contratacion.perfilFacturacion"
											return-object
											:rules="[rules.req]"
											clearable
											item-text="TextoPerfilFacturacion"
										>
										</v-select>
									</v-col>
								</v-row>
								<v-divider /> -->
								<v-row class="ma-1">
									<v-col cols="12" class="col-md-6" v-for="i in 6" :key="i">
										<aux-input :title="`Potencia P${i}`">
											<v-text-field
												disabled
												filled
												dense
												hide-details="auto"
												:placeholder="`Introduzca Potencia P${i}`"
												:value="contrato.detallesContratacion[`p${i}`]"
												:rules="[rules.isNumber]"
											/>
										</aux-input>
									</v-col>
								</v-row>

								<v-divider />

								<aux-input title="Consumo Anual SIPS KWh">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="Introduzca consumo del SIPS"
										:value="contrato.detallesContratacion.consumoAnualSips"
									/>
								</aux-input>
							</v-card>
						</v-col>

						<v-col cols="12" class="col-md-6">
							<v-card outlined>
								<v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4">
									<v-card-subtitle class="pa-0">
										<v-icon small>mdi-pencil</v-icon>&nbsp;Servicios de valor
										añadido
									</v-card-subtitle>
								</v-sheet>

								<aux-input title="Grupo Producto">
									<v-select
										disabled
										filled
										placeholder="Seleccione un Grupo*"
										dense
										hide-details="auto"
										:value="contrato.serviciosValor.grupoProducto"
										:items="selects.serviciosValor.grupoProducto"
										item-text="TextoProductoGrupo"
										clearable
										@change="getProductoServicios"
										return-object
									/>
								</aux-input>
								<aux-input title="Producto">
									<v-select
										disabled
										filled
										placeholder="Seleccione un Producto"
										dense
										hide-details="auto"
										:value="contrato.serviciosValor.producto"
										:items="selects.serviciosValor.producto"
										item-text="TextoProducto"
										clearable
									>
									</v-select>
								</aux-input>
							</v-card>
						</v-col>
					</v-row>
				</v-tab-item>

				<!-- TAB CONTRATO -->

				<v-tab-item eager>
					<v-row>
						<v-col cols="12" class="col-md-6">
							<v-card outlined>
								<v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4">
									<v-card-subtitle class="pa-0">
										<v-icon small>mdi-pencil</v-icon>&nbsp;Datos titular
									</v-card-subtitle>
									<v-spacer />
								</v-sheet>

								<v-divider />
								<v-row class="ma-1">
									<v-col cols="12" sm="4">
										<h5>Identificador*</h5>
										<small>CIF, NIF, NIE</small>
									</v-col>
									<v-col>
										<v-text-field
											disabled
											dense
											hide-details="auto"
											filled
											placeholder="INTRODUZCA IDENTIFICADOR"
											:rules="[rules.req, rules.ValidateSpanishID]"
											:value="contrato.datosTitular.identificador"
										>
										</v-text-field>
									</v-col>
								</v-row>
								<v-divider />

								<aux-input title="Nombre *">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="INTRODUZCA NOMBRE"
										:rules="[!esEmpresa ? rules.req : true]"
										:value="contrato.datosTitular.nombre"
									/>
								</aux-input>
								<v-divider />

								<aux-input title="Apellido 1*">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="Introduzca apellido 1"
										:rules="[!esEmpresa ? rules.req : true]"
										:value="contrato.datosTitular.apellido1"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Apellido 2">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="Introduzca apellido 2"
										:value="contrato.datosTitular.apellido2"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Razón Social">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="INTRODUZCA RAZON SOCIAL"
										:rules="[esEmpresa ? rules.req : true]"
										:value="contrato.datosTitular.razonSocial"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Ciudad *">
									<v-autocomplete
										disabled
										dense
										hide-details="auto"
										hide-no-data
										filled
										placeholder="BUSCAR CIUDAD POR NOMBRE"
										:value="contrato.datosTitular.ciudad"
										item-text="texto"
										clearable
										cache-items
										:return-object="true"
										:loading="isLoading"
										:items="ciudadesPosibles.datosTitular"
										:rules="[rules.req]"
										:search-input.sync="busqueda.ciudad.datosTitular"
										:hint="contrato.datosTitular.sugerenciaCiudad"
										persistent-hint
									>
									</v-autocomplete>
								</aux-input>
								<v-divider />
								<aux-input title="Provincia *">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										:rules="[rules.req]"
										placeholder="INTRODUZCA PROVINCIA"
										:value="contrato.datosTitular.provincia"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Calle *">
									<v-combobox
										disabled
										:value="contrato.datosTitular.calle"
										dense
										hide-no-data
										hide-details="auto"
										filled
										placeholder="Nombre Calle"
										item-text="texto"
										:rules="[rules.req]"
										clearable
										:return-object="true"
										:loading="isLoadingCalle"
										:items="callesPosibles.datosTitular"
										@change="calleCambio('datosTitular')"
										:hint="contrato.datosTitular.sugerenciaCalle"
										persistent-hint
									></v-combobox>
								</aux-input>
								<v-divider />
								<aux-input title="Número *">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										:rules="[rules.req]"
										filled
										placeholder="INTRODUZCA NÚMERO"
										:value="contrato.datosTitular.numero"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Aclarador">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="INTRODUZCA ACLARADOR"
										:value="contrato.datosTitular.aclarador"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Codigo Postal *">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										:rules="[rules.req]"
										filled
										placeholder="INTRODUZCA CODIGO POSTAL"
										:value="contrato.datosTitular.codPostal"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Telefono fijo *">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										:rules="[
											contrato.datosTitular.telefonoMovil == null ||
											contrato.datosTitular.telefonoMovil == ''
												? rules.req
												: true,
											rules.isNumber,
										]"
										filled
										placeholder="INTRODUZCA TELEFONO"
										:value="contrato.datosTitular.telefonoFijo"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Telefono móvil *">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										:rules="[
											contrato.datosTitular.telefonoFijo == null ||
											contrato.datosTitular.telefonoFijo == ''
												? rules.req
												: true,
											rules.isNumber,
										]"
										filled
										placeholder="INTRODUZCA MOVIL"
										:value="contrato.datosTitular.telefonoMovil"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Email">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="INTRODUZCA EMAIL"
										:value="contrato.datosTitular.email"
									/>
								</aux-input>
								<v-divider />
							</v-card>
						</v-col>
						<v-col cols="12" class="col-md-6">
							<v-row no-gutters>
								<v-card outlined width="100%">
									<v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4">
										<v-card-subtitle class="pa-0">
											<v-icon small>mdi-pencil</v-icon>&nbsp;Datos Envío
										</v-card-subtitle>
										<v-spacer />
									</v-sheet>

									<v-divider />
									<aux-input title="Nombre *">
										<v-text-field
											disabled
											dense
											hide-details="auto"
											filled
											:rules="[rules.req]"
											placeholder="INTRODUZCA NOMBRE"
											:value="contrato.datosEnvio.nombre"
										/>
									</aux-input>
									<v-divider />

									<aux-input title="Ciudad *">
										<v-autocomplete
											disabled
											dense
											hide-details="auto"
											hide-no-data
											:rules="[rules.req]"
											filled
											placeholder="BUSCAR CIUDAD POR NOMBRE"
											:value="contrato.datosEnvio.ciudad"
											item-text="texto"
											clearable
											cache-items
											:return-object="true"
											:loading="isLoading"
											:items="ciudadesPosibles.datosEnvio"
											:search-input.sync="busqueda.ciudad.datosEnvio"
										>
										</v-autocomplete>
									</aux-input>
									<v-divider />

									<aux-input title="Provincia *">
										<v-text-field
											disabled
											dense
											hide-details="auto"
											filled
											:rules="[rules.req]"
											placeholder="INTRODUZCA LA PROVINCIA"
											:value="contrato.datosEnvio.provincia"
										/>
									</aux-input>
									<v-divider />
									<aux-input title="Calle *">
										<v-combobox
											disabled
											:value="contrato.datosEnvio.calle"
											dense
											hide-no-data
											hide-details="auto"
											filled
											placeholder="Nombre Calle"
											:rules="[rules.req]"
											item-text="texto"
											clearable
											:return-object="true"
											:loading="isLoadingCalle"
											:items="callesPosibles.datosEnvio"
											@change="calleCambio('datosEnvio')"
										></v-combobox>
									</aux-input>
									<v-divider />
									<aux-input title="Número *">
										<v-text-field
											disabled
											dense
											hide-details="auto"
											filled
											placeholder="INTRODUZCA NÚMERO"
											:rules="[rules.req]"
											:value="contrato.datosEnvio.numero"
										/>
									</aux-input>
									<v-divider />
									<aux-input title="Aclarador">
										<v-text-field
											disabled
											dense
											hide-details="auto"
											filled
											placeholder="INTRODUZCA ACLARADOR"
											:value="contrato.datosEnvio.aclarador"
										/>
									</aux-input>
									<v-divider />
									<aux-input title="Codigo Postal *">
										<v-text-field
											disabled
											dense
											hide-details="auto"
											filled
											:rules="[rules.req]"
											placeholder="INTRODUZCA CODIGO POSTAL"
											:value="contrato.datosEnvio.codPostal"
										/>
									</aux-input>
								</v-card>
								<v-card outlined width="100%" class="mt-2">
									<v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4">
										<v-card-subtitle class="pa-0">
											<v-icon small>mdi-note</v-icon>&nbsp;Observaciones
										</v-card-subtitle>
									</v-sheet>
									<v-textarea
										filled
										auto-grow
										no-resize
										style="min-height: 200px"
										:value="contrato.observaciones"
									></v-textarea>
								</v-card>
							</v-row>
						</v-col>
					</v-row>
				</v-tab-item>

				<!-- TAB OTROS -->

				<v-tab-item eager>
					<v-row>
						<v-col cols="12" class="col-md-6">
							<v-card outlined>
								<v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4">
									<v-card-subtitle class="pa-0">
										<v-icon small>mdi-pencil</v-icon>&nbsp;Datos bancarios
									</v-card-subtitle>
								</v-sheet>
								<v-divider />
								<aux-input title="Nombre Pagador *">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										:rules="[rules.req]"
										placeholder="Introduzca nombre"
										:value="contrato.datosBancarios.nombrePagador"
									/>
								</aux-input>
								<v-divider />
								<v-row class="ma-1">
									<v-col cols="12" sm="4">
										<h5>Identificador*</h5>
										<small>CIF, NIF NIE</small>
									</v-col>
									<v-col>
										<v-text-field
											disabled
											dense
											hide-details="auto"
											:rules="[rules.req]"
											filled
											placeholder="Introduzca identificador"
											:value="contrato.datosBancarios.identificador"
										/>
									</v-col>
								</v-row>
								<v-divider />
								<aux-input title="Tipo Cobro * ">
									<v-select
										disabled
										filled
										dense
										hide-details="auto"
										label="Seleccione un Tipo de Cobro*"
										:value="contrato.datosBancarios.tipoCobro"
										clearable
										return-object
										:rules="[rules.req]"
										:items="selects.bancarios.tipoCobro"
										item-text="TextoTipoCobro"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="IBAN *">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										:rules="[
											(v) =>
												contrato.datosBancarios.tipoCobro &&
												contrato.datosBancarios.tipoCobro.TextoTipoCobro ==
													'NO DOMICILIADO'
													? true
													: rules.req(v),
											rules.iban,
										]"
										placeholder="Introduzca IBAN"
										:value="contrato.datosBancarios.iban"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="BIC">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="Introduzca BIC"
										:value="contrato.datosBancarios.bic"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="CNAE">
									<v-autocomplete
										disabled
										dense
										hide-details="auto"
										filled
										label="Seleccione CNAE"
										:value="contrato.datosBancarios.cnae"
										:items="selects.bancarios.cnae"
										item-text="texto"
										clearable
										return-object
										cache-items
									>
									</v-autocomplete>
								</aux-input>
								<v-divider />
							</v-card>
						</v-col>
						<v-col cols="12" class="col-md-6">
							<v-card outlined>
								<v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4">
									<v-card-subtitle class="pa-0">
										<v-icon small>mdi-pencil</v-icon>&nbsp;Otros datos
									</v-card-subtitle>
								</v-sheet>
								<v-divider />
								<aux-input title="Periodo de lectura *">
									<v-select
										disabled
										dense
										hide-details="auto"
										filled
										label="Seleccione un periodo"
										:value="contrato.otrosDatos.periodoLectura"
										:items="selects.otros.periodoLectura"
										return-object
										:rules="[rules.req]"
										clearable
										item-text="TextoPeriodo"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Tipo Impresión Factura *">
									<v-select
										disabled
										dense
										hide-details="auto"
										filled
										label="Seleccione un Tipo de Impresión"
										:value="contrato.otrosDatos.tipoImpresionFactura"
										:rules="[rules.req]"
										:items="selects.otros.tipoImpresionFactura"
										item-text="Texto"
										clearable
										return-object
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Tipo Solicitud *">
									<v-select
										disabled
										filled
										dense
										hide-details="auto"
										label="Seleccione un Tipo de Solicitud"
										:value="contrato.otrosDatos.tipoSolicitud"
										:rules="[rules.req]"
										:items="selects.otros.tipoSolicitud"
										item-text="NombreSolicitudTipo"
										return-object
										clearable
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Modelo contrato *">
									<v-select
										disabled
										dense
										hide-details="auto"
										filled
										label="Seleccione un modelo de contrato"
										:value="contrato.otrosDatos.modeloContrato"
										:items="selects.otros.modeloContrato"
										return-object
										:rules="[rules.req]"
										item-text="DescripcionModeloDeImpresion"
										clearable
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Idioma contrato *">
									<v-select
										disabled
										dense
										hide-details="auto"
										filled
										label="Seleccione el idioma del contrato a aplicar"
										:value="contrato.otrosDatos.idiomaContrato"
										:items="selects.otros.idiomas"
										:rules="[rules.req]"
										return-object
										clearable
										item-text="TextoIdioma"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Fecha efecto *">
									<v-select
										disabled
										dense
										hide-details="auto"
										filled
										label="Seleccione una fecha"
										:value="contrato.otrosDatos.fechaEfecto"
										return-object
										clearable
										item-text="TextoFechaEfecto"
										:rules="[rules.req]"
										:items="selects.otros.fechaEfecto"
										@change="contrato.otrosDatos.activacionPrevista = null"
									/>
								</aux-input>
								<v-divider />
								<v-row class="ma-1">
									<v-col cols="12" sm="4">
										<h5>Activacion prevista</h5>
										<small
											>Solo disponible si se selecciona 'Según la fecha
											solicitada'</small
										>
									</v-col>
									<v-col>
										<v-text-field
											disabled
											type="date"
											dense
											hide-details="auto"
											filled
											:value="contrato.otrosDatos.activacionPrevista"
										/>
									</v-col>
								</v-row>
								<v-divider />
								<aux-input title="Codigo vendedor">
									<v-select
										disabled
										dense
										hide-details="auto"
										filled
										label="Seleccione un agente"
										:value="contrato.otrosDatos.codigoVendedor"
									/>
								</aux-input>
								<v-divider />
							</v-card>
						</v-col>
						<v-col cols="12" class="col-md-6" v-if="esEmpresa">
							<v-card outlined>
								<v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4">
									<v-card-subtitle class="pa-0">
										<v-icon small>mdi-pencil</v-icon>&nbsp;Datos representante
										(opcionales)
									</v-card-subtitle>
								</v-sheet>
								<v-divider />
								<aux-input title="Nombre del representante">
									<v-text-field
										disabled
										type="text"
										dense
										hide-details="auto"
										filled
										:value="contrato.datosRepresentante.nombreRepresentante"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Email representante">
									<v-text-field
										disabled
										type="text"
										dense
										hide-details="auto"
										filled
										:value="contrato.datosRepresentante.emailRepresentante"
										:rules="[rules.email]"
									/>
								</aux-input>
								<v-divider />
								<v-row class="ma-1">
									<v-col cols="12" sm="4">
										<h5>Identificador*</h5>
										<small>CIF, NIF, NIE</small>
									</v-col>
									<v-col>
										<v-text-field
											disabled
											dense
											hide-details="auto"
											filled
											placeholder="INTRODUZCA IDENTIFICADOR"
											:rules="[rules.ValidateSpanishID]"
											:value="
												contrato.datosRepresentante.identificadorRepresentante
											"
										/>
									</v-col>
								</v-row>
								<v-divider />
								<aux-input title="Colectivo">
									<v-select
										disabled
										dense
										hide-details="auto"
										filled
										label="Seleccione el colectivo"
										:value="contrato.datosRepresentante.colectivo"
										:items="selects.representante.colectivos"
										return-object
										clearable
										item-text="TextoColectivo"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Comentario">
									<v-text-field
										disabled
										dense
										hide-details="auto"
										filled
										placeholder="Introduzca el comentario"
										:value="contrato.datosRepresentante.comentario"
									/>
								</aux-input>
							</v-card>
						</v-col>
					</v-row>
				</v-tab-item>

				<!-- TAB DOCUMENTACION -->

				<v-tab-item eager v-if="$route.query.id">
					<v-row>
						<v-col cols="12">
							<v-card outlined>
								<v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4">
									<v-card-subtitle class="pa-0">
										<v-icon small>mdi-file-multiple</v-icon>&nbsp;DOCUMENTACION
									</v-card-subtitle>
									<v-spacer />
								</v-sheet>
								<v-data-table :items="documentos" :headers="documentosHeader">
									<template v-slot:item.acciones="{ item }">
										<v-btn
											@click="verDocumento(item.name, true)"
											dark
											text
											color="secondary"
											:loading="downloadingFile"
										>
											<v-icon left>mdi-download</v-icon>Descargar
										</v-btn>
										<v-btn
											@click="verDocumento(item.name, false)"
											dark
											text
											color="secondary"
											:loading="downloadingFile"
										>
											<v-icon left>mdi-eye</v-icon>Ver
										</v-btn>
									</template>
									<template v-slot:item.name="{ item }">
										<v-chip
											style="
												height: 36px;
												min-width: 64px;
												font-weight: 500;
												letter-spacing: 0.0892857143em;
											"
											color="transparent"
											text-color="secondary"
											outlined
											label
											>{{ item.name }}</v-chip
										>
									</template>
								</v-data-table>
							</v-card>
						</v-col>
					</v-row>
				</v-tab-item>

			</v-tabs-items>
		</v-form>
		<!-- {{selects.contratacion.perfilFacturacion}} -->
	</v-container>
</template>

<script>
// import axios from "redaxios";
import {
	cups,
	ValidateSpanishID,
	email,
	req,
	isNumber,
	spainIdType,
	iban,
} from "@/utils/validations.js";
import {
	processToken,
	calcularConsumoAnual,
	obtenerNombreTarifaML,
	decode64,
	downloadFile
} from "@/utils/index.js";
import extensions from "@/utils/mimeExtensions.js";

export default {
	components: {
		AuxInput: () => import("@/components/AuxInput.vue"),
	},
	data() {
		return {
			cargandoContrato: false,
			esBorrador: true,
			errors: false,
			incidencias: [],
			tab: 0,
			documentos: [],
			documentosHeader: [
				{ text: "Nombre", value: "name" },
				{ text: "Acciones", value: "acciones", sortable: false },
			],
			downloadingFile: false,
			contrato: {
				puntoSuministro: {
					cups: "",
					codDistribuidora: null,
					codComercializadora: 1017, //? PUESTO A PEDAL,
					sugerenciaCiudad: null,
					ciudad: null,
					provincia: null,
					calle: null,
					numero: null,
					aclarador: null,
					codPostal: null,
					ficheroAdjunto: null,
				},
				detallesContratacion: {
					tarifa: null,
					producto: null,
					perfilFacturacion: null,
					p1: "",
					p2: "",
					p3: "",
					p4: "",
					p5: "",
					p6: "",
					consumoAnualSips: null,
					ConsumoEstimadoP1: null,
					ConsumoEstimadoP2: null,
					ConsumoEstimadoP3: null,
					ConsumoEstimadoP4: null,
					ConsumoEstimadoP5: null,
					ConsumoEstimadoP6: null,
				},
				serviciosValor: {
					grupoProducto: null,
					producto: null,
				},
				datosTitular: {
					identificador: "",
					nombre: null,
					apellido1: null,
					apellido2: null,
					razonSocial: null,
					ciudad: null,
					provincia: null,
					calle: null,
					numero: null,
					aclarador: null,
					codPostal: null,
					telefonoFijo: null,
					telefonoMovil: null,
					email: null,
				},
				datosEnvio: {
					nombre: null,
					ciudad: null,
					provincia: null,
					calle: null,
					numero: null,
					aclarador: null,
					codPostal: null,
				},
				observaciones: null,
				datosBancarios: {
					nombrePagador: null,
					identificador: null,
					tipoCobro: null,
					iban: null,
					bic: null,
					cnae: null,
				},
				otrosDatos: {
					periodoLectura: null,
					tipoImpresionFactura: null,
					tipoSolicitud: null,
					modeloContrato: null,
					idiomaContrato: null,
					fechaEfecto: null,
					activacionPrevista: null,
					codigoVendedor: null,
				},
				datosRepresentante: {
					nombreRepresentante: null,
					emailRepresentante: null,
					telefonoRepresentante: null,
					identificador: null,
					comentario: null,
					colectivo: null,
				},
				productoFlexible: {
					feeEnergia: 0.25,
					p1: 0,
					p2: 0,
					p3: 0,
					p4: 0,
					p5: 0,
					p6: 0,
				},
				certificadoElectronico: false,
				enviarSMS: false,
				enviarEmail: false,
			},
			selects: {
				serviciosValor: {
					grupoProducto: [],
					producto: [],
				},
				contratacion: {
					tarifas: [],
					producto: [],
					perfilFacturacion: [],
				},
				bancarios: {
					tipoCobro: [],
					cnae: [],
				},
				otros: {
					periodoLectura: [],
					tipoSolicitud: [],
					modeloContrato: [],
					idiomas: [],
					fechaEfecto: [],
					tipoImpresionFactura: [
						{
							Valor: "P",
							Texto: "Papel & email",
						},
						{
							Valor: "Q",
							Texto: "Papel",
						},
						{
							Valor: "E",
							Texto: "Email",
						},
						{
							Valor: "W",
							Texto: "Web",
						},
						{
							Valor: "R",
							Texto: "Recibo Bancario",
						},
					],
				},
				representante: {
					colectivos: [],
				},
				flexible: {
					feeEnergia: [],
					incrementoPotencia: [],
				},
			},

			ciudadesPosibles: {
				puntoSuministro: [],
				datosTitular: [],
				datosEnvio: [],
			},
			callesPosibles: { puntoSuministro: [], datosTitular: [], datosEnvio: [] },

			detallesFlexible: {
				show: 0,
			},

			busqueda: {
				ciudad: {
					puntoSuministro: null,
					datosTitular: null,
					datosEnvio: null,
				},
				calle: {
					puntoSuministro: null,
					datosTitular: null,
					datosEnvio: null,
				},
			},

			isLoading: false,
			isLoadingCalle: false,

			loadingData: true,

			ciudadFound: false,

			flexibleName: null,

			debouncer: {},

			idEmpresa: null,
			idUsuario: null,
			idAgente: null,

			NombreAgente: this.$store.getters.getDatosEmpresa.iss.NombreAgente,
		};
	},
	computed: {
		esEmpresa() {
			return (
				this.contrato.datosTitular.identificador != null &&
				spainIdType(this.contrato.datosTitular.identificador) == "cif"
			);
		},
		esProductoFlexible() {
			if (this.contrato.detallesContratacion.tarifa == null) return false;
			return this.contrato.detallesContratacion.producto == null;
		},
		rules() {
			return {
				cups,
				ValidateSpanishID,
				email,
				isNumber,
				iban,
				req: (value) => (!this.esBorrador ? req(value) : true),
			};
		},
		atrPotencia() {
			if (
				!this.esProductoFlexible ||
				!this.contrato.detallesContratacion.tarifa
			)
				return {};

			let tarifa =
				this.contrato.detallesContratacion.tarifa.TextoTarifa.replaceAll(
					/ ML/g,
					""
				);
			let incrementoP1 = this.contrato.productoFlexible.p1;
			let incrementoP2 = this.contrato.productoFlexible.p2;
			let incrementoP3 = this.contrato.productoFlexible.p3;
			let incrementoP4 = this.contrato.productoFlexible.p4;
			let incrementoP5 = this.contrato.productoFlexible.p5;
			let incrementoP6 = this.contrato.productoFlexible.p6;

			switch (tarifa) {
				case "2.0A":
				case "2.0DHA":
				case "2.0DHS":
					return {
						p1: 38.043426 + incrementoP1,
					};
				case "2.1A":
				case "2.1DHA":
				case "2.1DHS":
					return {
						p1: 44.44471 + incrementoP1,
					};
				case "3.0A":
					return {
						p1: 40.728885 + incrementoP1,
						p2: 24.43733 + incrementoP2,
						p3: 16.291555 + incrementoP3,
					};
				case "3.1A":
					return {
						p1: 59.173468 + incrementoP1,
						p2: 36.490689 + incrementoP2,
						p3: 8.367731 + incrementoP3,
					};
				case "6.1A":
					return {
						p1: 39.139427 + incrementoP1,
						p2: 19.586654 + incrementoP2,
						p3: 14.334178 + incrementoP3,
						p4: 14.334178 + incrementoP4,
						p5: 14.334178 + incrementoP5,
						p6: 6.540177 + incrementoP6,
					};
				case "6.2":
					return {
						p1: 22.158348 + incrementoP1,
						p2: 11.088763 + incrementoP2,
						p3: 14.334178 + incrementoP3,
						p4: 8.115134 + incrementoP4,
						p5: 8.115134 + incrementoP5,
						p6: 3.702649 + incrementoP6,
					};
				case "6.3":
					return {
						p1: 18.916198 + incrementoP1,
						p2: 9.466286 + incrementoP2,
						p3: 6.92775 + incrementoP3,
						p4: 6.92775 + incrementoP4,
						p5: 6.92775 + incrementoP5,
						p6: 3.160887 + incrementoP6,
					};
				case "6.4":
					return {
						p1: 13.706285 + incrementoP1,
						p2: 6.859077 + incrementoP2,
						p3: 5.019707 + incrementoP3,
						p4: 5.019707 + incrementoP4,
						p5: 5.019707 + incrementoP5,
						p6: 2.290315 + incrementoP6,
					};
				case "2.0TD":
					return {
						p1: 30.67266 + incrementoP1,
						p2: 1.424359 + incrementoP2,
					};
				case "3.0TD":
					return {
						p1: 19.596985 + incrementoP1,
						p2: 13.781919 + incrementoP2,
						p3: 7.005384 + incrementoP3,
						p4: 6.106183 + incrementoP4,
						p5: 4.399377 + incrementoP5,
						p6: 2.636993 + incrementoP6,
					};
				case "6.1TD":
					return {
						p1: 30.535795 + incrementoP1,
						p2: 25.894705 + incrementoP2,
						p3: 14.909149 + incrementoP3,
						p4: 12.094449 + incrementoP4,
						p5: 3.93866 + incrementoP5,
						p6: 2.108693 + incrementoP6,
					};
				case "6.2TD":
					return {
						p1: 13.706285 + incrementoP1,
						p2: 6.859077 + incrementoP2,
						p3: 5.019707 + incrementoP3,
						p4: 5.019707 + incrementoP4,
						p5: 5.019707 + incrementoP5,
						p6: 2.290315 + incrementoP6,
					};
				case "6.3TD":
					return {
						p1: 13.706285 + incrementoP1,
						p2: 6.859077 + incrementoP2,
						p3: 5.019707 + incrementoP3,
						p4: 5.019707 + incrementoP4,
						p5: 5.019707 + incrementoP5,
						p6: 2.290315 + incrementoP6,
					};
				case "6.4TD":
					return {
						p1: 13.706285 + incrementoP1,
						p2: 6.859077 + incrementoP2,
						p3: 5.019707 + incrementoP3,
						p4: 5.019707 + incrementoP4,
						p5: 5.019707 + incrementoP5,
						p6: 2.290315 + incrementoP6,
					};
				default:
					return { p1: 0 };
			}
		},
	},
	methods: {
		getDatosBorrador() {
			return new Promise(async (resolve, reject) => {
				try {
					let { data } = await axios({
						method: "get",
						url: `${process.env.VUE_APP_OUR_API_URL}/contratos.php`,
						params: {
							id: this.$route.query.id,
							token: this.$store.getters.getJwtEmpresa,
						},
					});
					data = data[0];

					this.idUsuario = data.IdUsuario;
					if (data.NombreAgente) this.NombreAgente = data.NombreAgente;

					let promesasCiudades = [];
					if (data.CiudadSuministro != "" && data.CiudadSuministro != null)
						promesasCiudades.push(
							this.buscarCiudad(data.CiudadSuministro, "puntoSuministro")
						);
					if (data.CiudadTitular != "" && data.CiudadTitular != null)
						promesasCiudades.push(
							this.buscarCiudad(data.CiudadTitular, "datosTitular")
						);
					if (data.CiudadEnvio != "" && data.CiudadEnvio != null)
						promesasCiudades.push(
							this.buscarCiudad(data.CiudadEnvio, "datosEnvio")
						);

					await Promise.all(promesasCiudades);

					this.contrato.puntoSuministro.ciudad =
						this.ciudadesPosibles.puntoSuministro.find(
							(ciudad) =>
								ciudad.desCiudad == data.CiudadSuministro &&
								ciudad.codigoProveedorMunicipio ==
									data.CodigoProveedorMunicipioSuministro
						);
					this.contrato.datosTitular.ciudad =
						this.ciudadesPosibles.datosTitular.find(
							(ciudad) =>
								ciudad.desCiudad == data.CiudadTitular &&
								ciudad.codigoProveedorMunicipio ==
									data.CodigoProveedorMunicipioTitular
						);
					this.contrato.datosEnvio.ciudad =
						this.ciudadesPosibles.datosEnvio.find(
							(ciudad) =>
								ciudad.desCiudad == data.CiudadEnvio &&
								ciudad.codigoProveedorMunicipio ==
									data.CodigoProveedorMunicipioEnvio
						);

					let promesasCalles = [];
					if (this.contrato.puntoSuministro.ciudad != null)
						promesasCalles.push(this.buscarCalle("puntoSuministro"));
					if (this.contrato.datosTitular.ciudad != null)
						promesasCalles.push(this.buscarCalle("datosTitular"));
					if (this.contrato.datosEnvio.ciudad != null)
						promesasCalles.push(this.buscarCalle("datosEnvio"));

					await Promise.all(promesasCalles);

					this.contrato.detallesContratacion.tarifa =
						this.selects.contratacion.tarifas.find(
							(tarifa) => tarifa.TextoTarifa == data.Tarifa
						);
					if (this.contrato.detallesContratacion.tarifa != null) {
						await this.getProductoContratacion(
							this.contrato.detallesContratacion.tarifa
						);
					}

					this.contrato = {
						estado: data.estado,
						enviarSMS: Boolean(Number(data.enviarSMS)),
						enviarEmail: Boolean(Number(data.enviarEmail)),
						puntoSuministro: {
							...this.contrato.puntoSuministro,
							cups: data.CodigoCUPS,
							codDistribuidora: data.CodigoREEDistribuidora,
							codComercializadora: 1017,
							sugerenciaCiudad: data.CiudadSuministro,
							provincia: data.ProvinciaSuministro,
							calle: this.callesPosibles.puntoSuministro.find(
								(calle) => calle.desCalle == data.CallePuntoSuministro
							),
							numero: data.Numero,
							aclarador: data.Aclarador,
							codPostal: data.CodPostal,
							ficheroAdjunto: null,
						},
						detallesContratacion: {
							...this.contrato.detallesContratacion,
							producto:
								this.selects.contratacion.producto.find(
									(producto) => producto.TextoTarifaGrupo == data.TarifaGrupo
								) || null,
							perfilFacturacion:
								this.selects.contratacion.perfilFacturacion.find(
									(perfil) =>
										perfil.TextoPerfilFacturacion ==
										data.PerfilFacturacionContratoTarifa
								) || this.selects.contratacion.perfilFacturacion[0],
							p1: data.P1,
							p2: data.P2,
							p3: data.P3,
							p4: data.P4,
							p5: data.P5,
							p6: data.P6,
							consumoAnualSips: data.ConsumoEstimado,
							ConsumoEstimadoP1: data.ConsumoEstimadoP1,
							ConsumoEstimadoP2: data.ConsumoEstimadoP2,
							ConsumoEstimadoP3: data.ConsumoEstimadoP3,
							ConsumoEstimadoP4: data.ConsumoEstimadoP4,
							ConsumoEstimadoP5: data.ConsumoEstimadoP5,
							ConsumoEstimadoP6: data.ConsumoEstimadoP6,
						},
						serviciosValor: {
							grupoProducto: null,
							producto: data.Producto,
						},
						datosTitular: {
							...this.contrato.datosTitular,
							identificador: data.Identificador,
							nombre: data.NombreTitular,
							apellido1: data.Apellido1Titular,
							apellido2: data.Apellido2Titular,
							razonSocial: data.RazonSocialTitular,
							provincia: data.ProvinciaTitular,
							calle: this.callesPosibles.datosTitular.find(
								(calle) => calle.desCalle == data.CalleTitular
							),
							numero: data.NumeroTitular,
							aclarador: data.AclaradorTitular,
							codPostal: data.CPTitular,
							telefonoFijo: data.TfnoTitular,
							telefonoMovil: data.Movil,
							email: data.EmailTitular,
						},
						datosEnvio: {
							...this.contrato.datosEnvio,
							nombre: data.NombreEnvio,
							provincia: data.ProvinciaEnvio,
							calle: this.callesPosibles.datosEnvio.find(
								(calle) => calle.desCalle == data.CalleEnvio
							),
							numero: data.NumeroEnvio,
							aclarador: data.AclaradorEnvio,
							codPostal: data.CPEnvio,
						},
						observaciones: data.Observaciones,
						datosBancarios: {
							nombrePagador: data.NombrePagador,
							identificador: data.IdentificadorPagador,
							tipoCobro: this.selects.bancarios.tipoCobro.find(
								(tipo) => tipo.TextoTipoCobro == data.TextoTipoCobro
							),
							iban: data.IBAN,
							bic: data.BIC,
							cnae: this.selects.bancarios.cnae.find(
								(cnae) => cnae.CodigoCNAE == data.CodigoCNAE
							),
						},
						otrosDatos: {
							periodoLectura: this.selects.otros.periodoLectura.find(
								(periodo) => periodo.TextoPeriodo == data.TextoPeriodo
							),
							tipoImpresionFactura:
								this.selects.otros.tipoImpresionFactura.find(
									(tipo) => tipo.Valor == data.TextoTipoImpresion
								),
							tipoSolicitud: this.selects.otros.tipoSolicitud.find(
								(tipo) => tipo.OperacionAsociada == data.TipoSolicitud
							),
							modeloContrato: this.selects.otros.modeloContrato.find(
								(modelo) =>
									modelo.IdModeloDeImpresion == data.IdModeloContrato &&
									modelo.DescripcionModeloDeImpresion ==
										data.TextoModeloContrato
							),
							idiomaContrato: this.selects.otros.idiomas.find(
								(idioma) => idioma.IdIdiomaInforme == data.IdIdiomaInforme
							),
							fechaEfecto: this.selects.otros.fechaEfecto.find(
								(fecha) => fecha.IdSolicitudTipoFechaEfecto == data.FechaEfecto
							),
							activacionPrevista: data.ActivacionPrevista,
							codigoVendedor: data.CodigoVendedor,
						},
						datosRepresentante: {
							nombreRepresentante: data.Representante,
							emailRepresentante: data.EmailRep,
							telefonoRepresentante: data.SMSRep,
							identificador: data.IdentificadorRep,
							comentario: data.Comentario,
							colectivo: this.selects.representante.colectivos.find(
								(colectivo) => colectivo.IdColectivo == data.IdColectivoRep
							),
						},
						productoFlexible: {
							feeEnergia: null,
							p1: null,
							p2: null,
							p3: null,
						},
						certificadoElectronico: !!data.IsFirmaDigital,
					};

					if (this.contrato.datosTitular.Identificador != null)
						this.buscarCliente(false);

					resolve({ flexible: data.TarifaGrupo });
				} catch (e) {
					reject(e);
					console.log(e);
				}
			});
		},
		buscarCiudad(filtro, target = "puntoSuministro") {
			return new Promise((resolve) => {
				clearTimeout(this.debouncer[target]);
				this.debouncer[target] = setTimeout(() => {
					axios({
						url: "https://apicallejero.portalswitching.com/api/v1/Ciudad/GetDTOALLbyTexto",
						method: "POST",
						data: [
							{
								FiltroTexto: filtro
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, ""),
							},
						],
					}).then((res) => {
						this.ciudadesPosibles[target] = res.data.map((item) => {
							item.texto = `${item.desCiudad} (${item.desProvincia})`;
							return item;
						});
						this.isLoading = false;
						resolve();
					});
				}, 500);
			});
		},
		buscarCalle(target = "puntoSuministro") {
			return new Promise((resolve) => {
				let { codMunicipio, codProvincia } = this.contrato[target].ciudad;
				axios({
					url: `https://apicallejero.portalswitching.com/api/v1/Calle/GetDTOALLCalles/CodigoMunicipio/${codMunicipio}/CodProvincia/${codProvincia}`,
					method: "GET",
				}).then((res) => {
					let temp = res.data.map((item) => {
						item.texto = `${item.desCalle} (${item.codigoPostal})`;
						return item;
					});

					this.callesPosibles[target] = temp;
					this.isLoadingCalle = false;
					resolve();
				});
			});
		},
		getGrupoProductoServicios() {
			return new Promise((resolve) => {
				axios({
					method: "get",
					url: `${process.env.VUE_APP_API_URL}/GrupoProducto/GetAllProductoGrupo/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
				}).then((res) => {
					this.selects.serviciosValor.grupoProducto = processToken(
						res.data
					).iss;
					resolve();
				});
			});
		},
		getProductoServicios(value) {
			return new Promise((resolve) => {
				if (value == null) {
					this.selects.serviciosValor.producto = [];
					return;
				}
				axios({
					method: "get",
					url: `${process.env.VUE_APP_API_URL}/Producto/GetAllProducto/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}/IdProductoGrupo/${value.IdProductoGrupo}`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
				}).then((res) => {
					this.selects.serviciosValor.producto = processToken(res.data).iss;
					resolve();
				});
			});
		},
		getTarifasContratacion() {
			return new Promise((resolve) => {
				axios({
					method: "get",
					url: `${process.env.VUE_APP_API_URL}/Tarifa/GetDTOAll/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
				}).then((res) => {
					this.selects.contratacion.tarifas = processToken(res.data).iss;
					resolve();
				});
			});
		},
		getProductoContratacion(value) {
			return new Promise((resolve) => {
				if (value == null) {
					this.selects.contratacion.producto = [];
					return;
				}
				// console.log(value);
				axios({
					method: "get",
					url: `${process.env.VUE_APP_API_URL}/Tarifa/GetDTOAllGruposTarifa/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}/Tarifa/${value.IdTarifa}`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
				}).then((res) => {
					this.selects.contratacion.producto = processToken(
						res.data
					).iss.filter(({ TextoTarifaGrupo }) => {
						let empresa = this.$store.getters.getNombreEmpresa.toUpperCase();
						if (empresa == "ALUMBRAENERGIA") empresa = "ALUMBRA";
						return (
							!TextoTarifaGrupo.toUpperCase().includes("General") &&
							!TextoTarifaGrupo.toUpperCase().includes("Empleados") &&
							(TextoTarifaGrupo.toUpperCase().includes(empresa) ||
								TextoTarifaGrupo == "PRODUCTO WEB") &&
							(TextoTarifaGrupo.toUpperCase() == this.flexibleName ||
								!TextoTarifaGrupo.toUpperCase().includes("FLEXIBLE"))
						);
					});

					this.contrato.detallesContratacion.producto =
						this.selects.contratacion.producto.find(
							(p) => p.TextoTarifaGrupo == this.flexibleName
						) || null;

					if (
						this.flexibleName &&
						this.contrato.detallesContratacion.producto == null
					) {
						this.selects.contratacion.producto.push({
							TextoTarifaGrupo: this.flexibleName,
						});
						this.contrato.detallesContratacion.producto =
							this.selects.contratacion.producto.find(
								(p) => p.TextoTarifaGrupo == this.flexibleName
							) || null;
					}

					resolve();
				});
			});
		},
		getPerfilFacturacionContratacion() {
			return new Promise((resolve) => {
				axios({
					method: "get",
					url: `${process.env.VUE_APP_API_URL}/PeriodoFactura/GetDTOAllPeriodos/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}/Linea/1`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
				}).then((res) => {
					this.selects.contratacion.perfilFacturacion = processToken(
						res.data
					).iss;

					this.contrato.detallesContratacion.perfilFacturacion =
						this.selects.contratacion.perfilFacturacion[0];

					resolve();
				});
			});
		},
		getTipoCobroBancarios() {
			return new Promise((resolve) => {
				axios({
					method: "get",
					url: `${process.env.VUE_APP_API_URL}/TipoCobro/GetDTOAll/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
				}).then((res) => {
					this.selects.bancarios.tipoCobro = processToken(res.data).iss.filter(
						(tipo) => tipo.TextoTipoCobro == "DOMICILIADO"
					);
					resolve();
				});
			});
		},
		getCnaeBancarios() {
			return new Promise((resolve) => {
				axios({
					method: "get",
					url: `${process.env.VUE_APP_API_URL}/Empresa/GetAllCNAE/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
				}).then((res) => {
					this.selects.bancarios.cnae = processToken(res.data).iss.map(
						(item) => {
							item.texto = [item.CodigoCNAE, item.TextoCNAE]
								.filter((i) => i != null)
								.join(" - ");
							return item;
						}
					);
					resolve();
				});
			});
		},
		getPeriodoLecturaOtros() {
			return new Promise((resolve) => {
				axios({
					method: "get",
					url: `${process.env.VUE_APP_API_URL}/PeriodoFactura/GetDTOAll/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
				}).then((res) => {
					this.selects.otros.periodoLectura = processToken(res.data).iss.filter(
						(per) => per.TextoPeriodo == "MENSUAL"
					);
					resolve();
				});
			});
		},
		getTipoSolicitudOtros() {
			return new Promise((resolve) => {
				axios({
					method: "get",
					url: `${process.env.VUE_APP_API_URL}/SolicitudTipo/GetDTOAll/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
				}).then((res) => {
					this.selects.otros.tipoSolicitud = processToken(res.data).iss.filter(
						(item) => item.SelecionableDesdeContrato == true
					);
					resolve();
				});
			});
		},
		getModeloContratoOtros() {
			return new Promise((resolve) => {
				axios({
					method: "get",
					url: `${process.env.VUE_APP_API_URL}/Contrato/GetDTOAllModelosContrato/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}/Agente/${this.idAgente}`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
				}).then((res) => {
					this.selects.otros.modeloContrato = processToken(res.data).iss.filter(
						(modelo) =>
							modelo.DescripcionModeloDeImpresion != "Contrato General" &&
							modelo.DescripcionModeloDeImpresion.toLowerCase().includes(
								this.$store.getters.getNombreEmpresa.toLowerCase() ==
									"alumbraenergia"
									? "alumbra"
									: this.$store.getters.getNombreEmpresa.toLowerCase()
							)
					);
					resolve();
				});
			});
		},
		getIdiomasContratoOtros() {
			return new Promise((resolve) => {
				axios({
					method: "get",
					url: `${process.env.VUE_APP_API_URL}/Contrato/GetDTOAllIdiomasContrato/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}/Agente/${this.idAgente}`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
				}).then((res) => {
					this.selects.otros.idiomas = processToken(res.data).iss;
					resolve();
				});
			});
		},
		getFechaEfectoOtros() {
			return new Promise((resolve) => {
				axios({
					method: "get",
					url: `${process.env.VUE_APP_API_URL}/FechaEfecto/GetDTOAll/Empresa/1/Usuario/1`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
				}).then((res) => {
					let temp = processToken(res.data).iss;
					for (const option of temp) {
						option.TextoFechaEfecto = option.TextoFechaEfecto.replace(
							/ *\(2017\)/g,
							""
						);
					}
					this.selects.otros.fechaEfecto = temp;
					resolve();
				});
			});
		},
		getColectivoRepresentante() {
			return new Promise((resolve) => {
				axios({
					method: "post",
					url: `${process.env.VUE_APP_API_URL}/Contrato/GetAllColectivos`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
					data: { IdEmpresa: this.idEmpresa, IdUsuario: this.idUsuario },
				}).then((res) => {
					this.selects.representante.colectivos = processToken(res.data).iss;
					resolve();
				});
			});
		},
		getCapacidad() {
			return new Promise((resolve) => {
				axios({
					method: "post",
					url: `${process.env.VUE_APP_API_URL}/Contrato/GetAllTiposCapacidad`,
					headers: {
						Authorization: this.$store.getters.getToken,
					},
					data: { IdEmpresa: this.idEmpresa, IdUsuario: this.idUsuario },
				}).then((res) => {
					resolve();
				});
			});
		},
		flexibleDetalles() {
			return new Promise(async (resolve) => {
				this.detallesFlexible = {
					show: true,
				};
				resolve();
			});
		},
		getDocumentacion() {
			return new Promise((resolve, reject) => {
				axios({
					method: "GET",
					url: `${process.env.VUE_APP_OUR_API_URL}/subir_documentacion.php`,
					params: {
						idBorrador: this.$route.query.id,
						token: this.$store.getters.getJwtEmpresa,
					},
				})
					.then(({ data }) => {
						this.documentos = Object.values(data).map((f) => ({ name: f }));
						resolve();
					})
					.catch((e) => {
						// console.trace(e);
						this.$root.$emit(
							"snack",
							"No se ha podido encontrar la documentación"
						);
						reject();
					});
			});
		},
		verDocumento(name, download) {
			this.downloadingFile = true;
			axios({
				method: "GET",
				url: `${process.env.VUE_APP_OUR_API_URL}/subir_documentacion.php`,
				responseType: "blob",
				params: {
					idBorrador: this.$route.query.id,
					token: this.$store.getters.getJwtEmpresa,
					filename: name,
				},
			}).then((res) => {
				let extension = name.split(".")[name.split(".").length - 1].toLowerCase();

				let file = new File(
					[res.data],
					`borrador_${new Date().getTime()}.${extension}`,
					{
						type: extensions[extension],
					}
				);
													downloadFile(file, !download)

				this.downloadingFile = false;
				// window.open(fileURL);
			});
		},
	},
	mounted() {
		let str = this.$store.getters.getDatosEmpresa;
		// console.log(str)

		const { IdUsuario, IdAgente } = str.iss;
		const IdEmpresa = this.$store.getters.getIdEmpresa

		this.idAgente = IdAgente;
		this.idUsuario = IdUsuario;
		this.idEmpresa = IdEmpresa;

		for (let i = 0.25; i <= 30; i += 0.25) {
			this.selects.flexible.feeEnergia.push(i);
		}
		for (let i = 0; i <= 15; i += 0.5) {
			this.selects.flexible.incrementoPotencia.push(i);
		}

		Promise.all([
			this.getGrupoProductoServicios(),
			this.getTarifasContratacion(),
			this.getPerfilFacturacionContratacion(),
			this.getTipoCobroBancarios(),
			this.getCnaeBancarios(),
			this.getPeriodoLecturaOtros(),
			this.getTipoSolicitudOtros(),
			this.getModeloContratoOtros(),
			this.getIdiomasContratoOtros(),
			this.getFechaEfectoOtros(),
			this.getColectivoRepresentante(),
			this.getDocumentacion(),
			// this.getCapacidad();
		]).then(async () => {
			if (!!this.$route.query.id) {
				let data = await this.getDatosBorrador();
				if (this.contrato.detallesContratacion.tarifa != null) {
					this.flexibleName = data.flexible;
					this.getProductoContratacion(
						this.contrato.detallesContratacion.tarifa
					);
				}
			}
			this.loadingData = false;
		});
	},
	watch: {
		esBorrador(val) {
			this.cargarContrato(true);
		},
		esEmpresa(val) {
			if (!val) this.contrato.datosTitular.razonSocial = "";
			else {
				this.contrato.datosTitular.nombre = "";
				this.contrato.datosTitular.apellido1 = "";
				this.contrato.datosTitular.apellido2 = "";
			}
		},
	},
};
</script>

<style>
</style>